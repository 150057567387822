import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { ToastService } from '../toastService/toast.service';
import { StorageService } from '../storageService/storage.service';
import { EventsService } from '../events/events.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.baseUrl;
  public mode: any = 'dark';
  public darkmode: any = 'app';
  user: any;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private navCtrl: NavController,
    private toastService: ToastService,
    public events: EventsService,
  ) {
    this.events.subscribe('user:active', (data: any) => {
      //('userr service fetch:', data);
      this.user = data;
    });
  }

  post(url: any, data: any): Observable<any> {
    data.User = this.user;
    return this.http.post<any>(this.apiUrl + url, data).pipe(
      tap((_) => { }),
      catchError(this.handleError('data', []))
    );
  }
  login(url: any, data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + url, data).pipe(
      tap((_) => { }),
      catchError(this.handleError('data', []))
    );
  }
  logout() {
    this.storageService.remove('userInfo');
    this.storageService.getObject('tutorialWatched').then((result) => {
      if (result == null) {
        this.storageService.clear();
      }
      else
      {
        this.storageService.clear();
        this.storageService.set('tutorialWatched', 'true');
      }
    });
    setTimeout(() => {
      this.navCtrl.navigateRoot('/login');
    }, 100);
  }
  weblogout() {
    this.storageService.remove('userInfo');this.storageService.getObject('tutorialWatched').then((result) => {
      if (result == null) {
        this.storageService.clear();
      }
      else
      {
        this.storageService.clear();
        this.storageService.set('tutorialWatched', 'true');
      }
    });
    setTimeout(() => {
      this.navCtrl.navigateRoot('/web');
    }, 100);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
