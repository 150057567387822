import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    public toastCtrl: ToastController,
    public loadingController: LoadingController
  ) { }


  async presentToast(type, data) {
    const toast = await this.toastCtrl.create({
      message: data,
      color: type,
      duration: 4000,
      position: 'bottom',
      cssClass: 'toaster_bottom',
    });
    toast.present();
  }
  async presentToastError(data) {
    const toast = await this.toastCtrl.create({
      message: data,
      color: 'danger',
      duration: 4000,
      position: 'bottom',
      cssClass: 'toast-error'
      
    });
    toast.present();
  }


  async presentLoading() {
    this.loadingController
      .getTop()
      .then((loader) => {
        if (loader) {
          loader.dismiss();
        }
      })
      .catch(() => {
        this.loadingController.dismiss();
      });

    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      spinner: 'crescent',
      duration: 5000
    });
    await loading
      .present()
      .then(() => { })
      .catch(() => { });
  }

  dismissLoading() {
    this.loadingController
      .getTop()
      .then((loader) => {
        if (loader) {
          loader.dismiss();
        } else {
          this.loadingController
            .dismiss()
            .then(() => { })
            .catch(() => { });
        }
      })
      .catch(() => {
        this.loadingController.dismiss();
      });
  }
}
