import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateFormat'
})
@Injectable({
  providedIn: 'root'
})
export class DateFormat implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
     return moment(date).format(format);
    }
  }
}


