import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(array: any[], property: string, sortOrder: string): any[] {
    if (!array || !array.length || !property) {
      return array;
    }
    return array.sort((a, b) => {
      const aValue = a.Data && a.Data[property];
      const bValue = b.Data && b.Data[property];

      if (aValue && bValue) {
        const comparison = aValue.localeCompare(bValue);
        return sortOrder === 'asc' ? comparison : -comparison;
      } else if (aValue) {
        return -1; // aValue exists, bValue doesn't, so a comes before b
      } else if (bValue) {
        return 1; // bValue exists, aValue doesn't, so b comes before a
      } else {
        return 0; // Handle cases where both properties are missing
      }
    });
  }
}
